import React from "react"
// import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import LeadForm from "../components/leadform";
// import MobileLeadingForm from "../components/mobileleadform";
import Blog from "../components/landingblog";
import marketingWebsites from '../images/marketing-websites.svg'
import eCommerceDevelopment from '../images/e-commerce-development.svg'
import onlineMarketplaces from '../images/online-marketplaces.svg'
import webApplications from '../images/web-applications.svg'


// const Arrow = () => (<svg className={'mr-2'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path fillRule="evenodd" clipRule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM7 13H13.5858L11.2929 15.2929L12.7071 16.7071L17.4142 12L12.7071 7.29289L11.2929 8.70711L13.5858 11H7V13Z" fill="#3F2C7E"/>
// </svg>)

const Services = (props) => {
    const services = [
        {image: marketingWebsites, title: 'Custom Marketing Websites', intro: 'Marketing websites are the most common websites created. It is easy to underestimate their importance to businesses today. Most research is conducted on the web. Whether it is an accountant, lawyer, dentist, optometrist, a business partner or supplier, chances are you are you are looking them up online. So are your customers. Being found, easy to reach and on brand is crucial. Marketing websites do not have to be simple in functionality. Calendar and appointment management, content marketing and reviews management are some of the most useful features in building business websites. Customer communication is essential and when it comes to medical practices like optometrists and dentists HIPAA compliance is required. Marketing websites are the most common business websites, marketing websites done well are not that common.'},
        {image: eCommerceDevelopment, title: 'Custom E-Commerce Development', intro: 'E-commerce has been the face of digital transformation since the mid 90s. From books, travel and hotels to food and grocery delivery, e-commerce is part of everyday life. The growth has been exponential. Businesses with a powerful e-commerce presence are resilient even in times of major disruption. Customer experienced is elevated with advancement in faceted browsing and real time full text search, recommendation engines and product subscriptions. New products are promoted more easily to larger audiences. Technologies like augmented reality (AR) allow customers to experience products in space before buying. Custom e-commerce design requires an understanding not only at the technical level but also on consumer bhavior. High performant systems are essential for seamless user experience. Most consumer businesses can find great opportunity in setting up e-commerce as part of their operations.'},
        {image: onlineMarketplaces, title: 'Full Featured Online Marketplaces', intro: 'Online marketplaces have been a recent trend connecting enterpreneurs to customers and transforming services that used be primarily offline. Companies like Uber and Lyft are the most common example of online marketplaces. The concept has quickly spread to across sectors, connecting smaller businesses and individuals to customers faster than ever before. Developing marketplaces is complex. The relations between the platform, the vendors and the customer need to be architected carefully. User experience needs attention to detail with separate considerations to different roles in the platform. Marketplaces offer aspiring enterpreneurs amazing opportunities to lead digital transformation for traditional businesses. '},
        {image: webApplications, title: 'Cutting Edge Web Applications', intro: 'Web applications have changed the way software is written and distributed. Businesses can now benefit from custom software connected to the tools they already use to optimize business processes. Getting ideas off the ground is much quicker. Software as service (SaaS) is now the most common software delivery channel. Larger companies and new enterpreneurs and startups address their customer needs faster and adapt to market changes. Multi tenancy and data isolation, security, privacy, performance and high availability are some of the main concerns when building web applications from the technical stand point. The core challenge remains having a reliable process and technical partner to transform ideas into solutions.'},
    ];

    return (
        <Layout>
            <SEO title="Articles" description={`Web application development and business digital transformation blog. A dive on best practices, web security, application performance and next gen digital experiences.`}/>
           <div className={'relative'}><div className={'hidden xxl:block purple-rotated-div'}/></div>
           <div className={'relative'}><div className={'hidden xxl:block gray-rotated-div'}/></div>
            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}
            <div className={'mx-auto max-w-7xl text-purple-800 px-4'}>
                <h1 className={'max-w-2xl text-2xl lg:text-4xl font-bold leading-snug mb-4'}>From custom marketing websites to full featured web applications</h1>
                <h3 className={'max-w-3xl mb-8'}>We help businesses grow through web solutions that increase sales, reduce costs and optimize operations. We know the challenges and opportunities the web offers. Let’s build your custom website together, craft your unique e-commerce experience or get your web application off the ground.</h3>
                {services.map((p, i)=> (<div key={i} className={'flex flex-col lg:flex-row-reverse my-20'}>
                    <div className={'pr-4 w-full lg:w-2/5 mb-2 lg:mb-0'}>
                        <img src={p.image} alt={p.title}/>
                    </div>
                    <div className={'px-4 lg:p-0 w-full lg:w-3/5 flex flex-col justify-start'}>
                        <div className={'mb-4 lg:mb-0'}>
                            <p className={'uppercase tracking-normal text-sm font-bold mb-1 text-purple-200'}>Services</p>
                            <p className={'text-2xl mb-4 font-bold leading-7 lg:mb-8'}>{p.title}</p>
                        </div>
                        <p className={'max-w-xl mb-8'}>{p.intro}</p>
                        {/* <div className={'flex flex-wrap'}>
                            <div className={'font-bold flex items-center w-80 my-1'}>
                                <Arrow/><p>Websites for Accounting Firms</p>
                            </div>
                            <div className={'font-bold flex items-center w-80 my-1'}>
                                <Arrow/><p>Websites for Legal Firms and Advisors</p>
                            </div>
                            <div className={'font-bold flex items-center w-80 my-1'}>
                                <Arrow/><p>Websites for Local Businesses</p>
                            </div>
                            <div className={'font-bold flex items-center w-80 my-1'}>
                                <Arrow/><p>Websites for Optometrists and Dentists</p>
                            </div>
                        </div> */}
                    </div>
                </div>))}
            </div>

            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}
            <div className={''}>
                <Blog/>
            </div>
        </Layout>
    )
}

export default Services